<template>
  <RightSideBar
    v-if="openModal"
    close-button
    @close="$emit('close')"
    :loading="loading"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Actions/Privileges
      </p>
    </template>
    <template v-slot:subtitle>
      <div class="flex w-full mb-5">
        <card class=" p-3 size flex-grow mr-2">
          <div class="flex">
            <Icon size="xs" icon-name="icon-key" />
            <p class="text-blueCrayola ml-2 text-xs ">
              Role Name
            </p>
          </div>
          <p class="text-jet ml-6 text-xs uppercase font-semibold">
            {{ roleName }}
          </p>
        </card>
        <card class=" p-3 flex-grow ">
          <div class="flex">
            <Icon size="xs" icon-name="employees" />
            <p class="text-carrotOrange text-xs ml-2 ">
              Assigned To
            </p>
          </div>
          <div class="text-jet uppercase ml-6 flex text-xs font-semibold">
            <p>
              {{ `${userCount} ${userCount > 1 ? "USERS" : "USER"}` }}
            </p>
            <button
              @click="openAssignedModal = true"
              v-if="userCount >= 1"
              class="text-flame justify-self-end pl-7"
            >
              {{ `${userCount > 1 ? "VIEW ALL" : "VIEW"}` }}
            </button>
          </div>
        </card>
      </div>
    </template>
    <div>
      <div class="mb-3">
        <p class="flex-grow font-semibold">
          Apps and Sub-Apps
        </p>
        <p class=" pt-4 text-jet">
          Select an app to view granted action/privileges/forms.
        </p>
        <c-select
          variant="w-full mb-4"
          :options="modules"
          @onChange="handleModuleChange($event)"
        />
      </div>
      <scroll-container>
        <accordion
          v-for="(subModule, index) in subModules"
          :key="index"
          class="tab-details"
        >
          <template v-slot:title>
            <span class="text-sm font-extrabold ml-2 uppercase">{{
              subModule.name
            }}</span>
          </template>
          <p class=" text-xs opacity-70 text-romanSilver font-semibold">
            ACTIONS, PRIVILEGES AND FORMS
          </p>
          <table aria-label="this shows submodule privileges" class="w-full">
            <tbody>
              <tr v-for="(item, i) in subModule.privileges" :key="i">
                <td>
                  <checkbox
                    :label="item.name"
                    :value="item.id"
                    disabled
                    checkbox-size="height:16px; width:16px; margin-top:4px"
                    label-style="color:#333333"
                    v-model="selectedItems"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </accordion>
      </scroll-container>
    </div>

    <template v-slot:footer>
      <div
        @click="handleEdit"
        v-if="!isDefault"
        class="footerContainer mr-5 cursor-pointer  text-desire"
      >
        <p class=" mx-2">
          Edit This Role
        </p>
      </div>
    </template>
    <role-assigned-to
      :open-modal="openAssignedModal"
      @close="$emit('close'), (openAssignedModal = false)"
      :role-id="roleId"
      @back="openAssignedModal = false"
    />
  </RightSideBar>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RightSideBar from "@/components/RightSideBar";
import Card from "@/components/Card";
import Accordion from "@/components/Accordion";
import ScrollContainer from "@/components/ScrollContainer";
import Checkbox from "@/components/Checkbox";
import RoleAssignedTo from "./roleAssignedTo";

export default {
  components: {
    RightSideBar,
    Card,
    CSelect,
    Accordion,
    ScrollContainer,
    Checkbox,
    RoleAssignedTo,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    roleId: {
      type: String,
      default: ""
    },
    userCount: {
      type: [Number, String],
      default: 0
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      openAssignedModal: false,
      loading: true,
      modules: [],
      selectedItems: [],
      subModules: [],
      roleName: ""
    };
  },
  watch: {
    roleId() {
      this.getRolesPriviledges(this.roleId);
    }
  },
  methods: {
    async handleEdit() {
      try {
        await this.$handlePrivilege("usersAndRoles", "editRole");
        this.$router.push({
          name: "Edit role",
          params: { id: this.roleId }
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    async getRolesPriviledges(id) {
      this.loading = true;
      const response = await this.$_NewGetUpdateRoles(id);
      this.modules = [];
      response.data.modules.forEach(item => {
        if (item.hasPrivilege) {
          this.modules.push(item);
        }
      });
      this.roleName = response.data.name;
      this.loading = false;
    },
    handleModuleChange(item) {
      const hasModule = this.modules.find(module => module.id === item);
      if (hasModule) {
        this.subModules = [];
        hasModule.submodules.forEach(subModule => {
          if (subModule.hasPrivilege) {
            this.subModules.push(subModule);
          }
        });
        hasModule.submodules.reduce(this.reducer, []);
      }
    },
    reducer(acc, current) {
      const hasPrivileges = current.privileges.filter(
        item => item.hasPrivilege
      );
      if (hasPrivileges.length) {
        hasPrivileges.forEach(privilege => {
          this.selectedItems.push(privilege.id);
        });
      }
    }
  },
  mounted() {
    if (this.roleId) {
      this.getRolesPriviledges(this.roleId);
    }
  }
};
</script>

<style scoped>
.container {
  height: 52px;
}
.default {
  background: rgba(135, 142, 153, 0.15);
  padding: 5px;
  border-radius: 5px;
}
.size {
  min-width: 206px;
}
.tab-details {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  border-radius: 5px;
}
.footerContainer {
  border: 1px solid #ea3c53;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 44px;
  padding: 8px;
}
td {
  border: none;
  padding: 12px;
  min-height: 43px !important;
}
tr:nth-child(even) {
  background-color: #f7f7ff;
}
</style>
