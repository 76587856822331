<template>
  <RightSideBar
    v-if="openModal"
    close-button
    @close="$emit('close')"
    back-button
    @back="$emit('back')"
    :loading="loading"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple mt-3 -ml-4">
        Role Assigned To
      </p>
    </template>
    <template v-slot:subtitle>
      <p class=" mb-3">
        These employees have been assigned the
        <span class=" text-flame">Admin</span> role...
      </p>
    </template>

    <div
      v-for="(item, index) in users"
      :key="index"
      class="flex flex-col pl-2 container "
    >
      <div class="flex items-center">
        <div>
          <img
            class=""
            :src="item.photo"
            v-if="item.photo"
            alt="photo"
            style="height: 30px; width: 30px; border-radius: 5px"
          />
          <div
            style="height: 30px; width: 30px; border-radius: 5px;"
            class="text-blueCrayola flex justify-center
               border text-center font-semibold pt-1"
            v-else
          >
            {{ $getInitials(`${item.fname} ${item.lname}`) }}
          </div>
        </div>
        <div class="flex flex-col ml-4 flex-grow">
          <p>
            {{ `${item.fname} ${item.mname ? item.mname : ""} ${item.lname}` }}
          </p>
          <div
            class="text-xs flex font-semibold w- text-romanSilver  uppercase "
          >
            <p class="text-blueCrayola mr-2">
              {{ ` ${item.userDesignation ? item.userDesignation.name : ""}` }}
            </p>
            <p class="opacity-50 ">
              {{ item.roles[0].name }}
            </p>
          </div>
        </div>
      </div>
      <divider class="my-2" v-if="users[index + 1]" border-dash />
    </div>

    <template v-slot:footer>
      <div class="rightSideFooterContainer mr-5 cursor-pointer  text-desire">
        <p @click="$router.push({ name: 'EmployeeDirectory' })" class=" mx-2">
          Employee Directory
        </p>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import RightSideBar from "@/components/RightSideBar";
import Divider from "@/components/divider";

export default {
  components: {
    RightSideBar,
    Divider
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    roleId: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      openMaxout: false,
      loading: true,
      users: []
    };
  },
  watch: {},
  methods: {
    getUsers() {
      this.$_getAllOrgUsersWithOptions(
        `app=humanar&roleId=${this.roleId}&perPage=1000`
      ).then(({ data }) => {
        this.users = data.users;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style scoped>
.container {
  height: 52px;
}
.default {
  background: rgba(135, 142, 153, 0.15);
  padding: 5px;
  border-radius: 5px;
}
</style>
