<template>
  <RightSideBar
    v-if="openModal"
    submit="Add"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        My Subscription
      </p>
    </template>
    <template v-slot:subtitle>
      <p class="font-semibold pb-3 text-darkPurple">
        This shows your current subscription capacity and utilization details
      </p>
    </template>
    <scroll-container height="595px">
      <accordion
        v-for="(item,index) in modules"
        :key="index"
        class=" accordionContainer "
      >
        <template v-slot:title>
          <div class="flex items-center">
            <custom-image
              :slug="item.slug"
              class="mr-3"
            />
            <div class="flex flex-col flex-grow">
              <p class="font-semibold">
                {{ item.title }}
              </p>
              <p class="text-xs font-semibold text-romanSilver opacity-50 uppercase ">
                {{ `• ${item.subTitle}` }}
              </p>
            </div>
          </div>
        </template>
        <div
          v-for="(employee,employeeIndex) in employees"
          :key="employeeIndex"
          class="flex flex-col pl-2 mt-4 container "
        >
          <div class="flex items-center">
            <div>
              <img
                class=""
                :src="employee.photo"
                v-if="employee.photo"
                style="height: 30px; width: 30px; border-radius: 5px"
              >
              <div
                style="height: 35px; width: 35px; border-radius: 5px;"
                class="text-blueCrayola mr-3 flex justify-center
               border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${employee.title}`) }}
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <p>
                {{ employee.title }}
              </p>
              <div class="text-xs flex font-semibold w- text-romanSilver  uppercase ">
                <p class="opacity-50 mr-2">
                  {{ ` ${employee.subTitle}` }}
                </p>
                <p class="text-blueCrayola">
                  {{ employee.designationRole }}
                </p>
              </div>
            </div>
          </div>
          <divider
            class="mt-3"
            v-if="employees[employeeIndex + 1]"
            border-dash
          />
        </div>
      </accordion>
    </scroll-container>
  </RightSideBar>
</template>

<script>
import RightSideBar from '@/components/RightSideBar';
import Accordion from '@/components/Accordion';
import ScrollContainer from '@/components/ScrollContainer';
import Divider from '@/components/divider';
import CustomImage from '@/components/CustomImage';

export default {
  components: {
    RightSideBar, CustomImage, Accordion, ScrollContainer, Divider,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      modules: [
        {
          id: '1',
          title: 'Analytics (Free)',
          subTitle: '8 users can access this module',
          slug: 'analytics',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
        {
          title: 'Employees',
          id: '2',
          subTitle: '12 of 13 users can access this module',
          slug: 'employees',
          open: false,
          selected: false,
        },
      ],
      employees: [
        {
          id: '1',
          title: 'Esther Klinsman',
          subTitle: 'CHIEF OPERATIONS OFFICER ',
          designationRole: '• ADMIN',
          photo: null,
          selected: false,
        },
        {
          title: 'King Ladipo',
          id: '2',
          subTitle: 'CHIEF FINANCIAL OFFICER ',
          designationRole: '• PAYROLL',
          photo: null,
          selected: false,
        },
        {
          title: 'King Ladipo',
          id: '3',
          subTitle: 'CHIEF FINANCIAL OFFICER ',
          designationRole: '• PAYROLL',
          photo: null,
          selected: false,
        },
      ],
    };
  },

  methods: {
  },

};
</script>

<style lang="scss" scoped>
.accordionContainer{
border: 1px solid #878E99;
box-sizing: border-box;
border-radius: 5px;
}

</style>
